/* src/App.css */
.app {
  min-height: 100vh; /* Ensure the container takes full viewport height */
  display: flex;
  flex-direction: column;
}

.footer {
  margin-top: auto; /* Push the footer to the bottom */
}
