/* src/HomePage.css */
.container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f7f7f7; /* Light background */
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  }
  
  h1 {
    color: #0044cc; /* SSB Automations blue */
    font-family: 'Roboto', sans-serif;
    text-align: center;
    margin-bottom: 30px;
  }
  
  input[type="text"] {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
  }
  
  button {
    width: 100%;
    padding: 10px;
    background-color: #0044cc;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  button:hover {
    background-color: #003399; /* Darker blue for hover */
  }
  
  .details {
    margin-top: 30px;
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  }
  
  .details strong {
    color: #333;
    font-size: 18px;
  }
  
  .table {
    width: 100%;
    margin-top: 15px;
    border-collapse: collapse;
  }
  
  .table td {
    padding: 10px;
    border-bottom: 1px solid #ddd;
  }
  
  .no-details {
    margin-top: 30px;
    color: #cc0000; /* Red for no details */
    font-size: 18px;
    text-align: center;
  }
  